import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { firebase } from '../firebase';
import Avatar from 'react-avatar';
// import { auth } from 'firebase';

class Sidebar extends Component {

  constructor(props) {
    super()

    this.state = {
      loading: false,
      listClass: [],
      schoolId: '',
      // defaultClass: '',
      dataClass: {},
    };
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        i !== 'loading' && i !== 'listClass' && i !== 'schoolId' && i !== 'dataClass' &&
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

    const { authData } = this.props;

    const userData = authData != null ? authData.userData : null;
    
    if (userData != null) {
      const schoolId = userData.key;
      // const defaultClass = userData.defaultClass;

      // this.setState({loading: true, schoolId, defaultClass});
      this.setState({loading: true, schoolId});

      // console.log(userData);
      // console.log(`escolas/${schoolId}/acessoSeriesNovo`);

      firebase.db.ref(`escolas/${schoolId}/acessoSeriesNovo`)
        .orderByChild('modo')
        // TODO: MUDAR MODO DA HISTÓRIA PARA DESENHO!
        .equalTo('draw-coletivo')
        .on('value', snapshot => {
          const val = snapshot.val();

          // console.log('lista turmas:');
          // console.log(val);

          let listClass = [];
          let dataClass = {};

          if (val != null) {
            Object.keys(val).forEach((k) => {
              const data = val[k];
              dataClass[k] = data;

              listClass.push(k);
            });
          }

          listClass.sort();

          // remove a turma professor do menu
          if (listClass.indexOf("Professor") >= 0) {
            listClass.splice(listClass.indexOf("Professor"), 1);
          }

          // console.log(listClass);

          // listClass = listClass.filter(c => c.indexOf(defaultClass) !== -1);

          this.setState({loading: false, listClass, dataClass});
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentWillUnmount() {
    if (this.state.schoolId != null) {
      firebase.db.ref(`escolas/${this.state.schoolId}/acessoSeriesNovo`).off();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      i !== 'loading' && i !== 'listClass' && i !== 'schoolId' && i !== 'dataClass' &&
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/class', state: 'basicClassMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
  }

  render () {
    // const listaTurmas = [
    //   'Teste',
    //   'Infantil1A',
    //   'ImaginaKIDS',
    // ];

    // listaTurmas.sort();

    const {
      authData
    } = this.props;

    // const {
    //   listClass
    // } = this.state;

    // console.log("vetor de turmas:");
    // console.log(this.state.turmas);

    const schoolId = authData && authData.userData && authData.userData.key;

    // const dataClass = {...this.state.dataClass};

    // console.log("dataClass");
    // console.log(dataClass);

    return (
      // <AuthUserContext.Consumer>
      //   {authData =>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <Link className="sidebar-brand brand-logo" to="/"><img src={require("../assets/images/logo.svg")} alt="logo" /></Link>
          {/* <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={require("../assets/images/logo-mini.svg" )} alt="logo" /></a> */}
          <Link className="sidebar-brand brand-logo-mini pt-3" to="/"><img src={require("../assets/images/logo-mini.svg" )} alt="logo" /></Link>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <div className="profile-image">
                      <img src={ require("../assets/images/faces/face8.jpg")} alt="profile" />
                    </div> */}
                    {/* <Avatar color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} size="40" round={true} name={authData && authData.userData && authData.userData.nome} /> */}
                    <Avatar src={`https://firebasestorage.googleapis.com/v0/b/startupweekend-175921.appspot.com/o/logos%2F${schoolId}.png?alt=media`}
                      color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} size="40" round={true} name={authData && authData.userData && authData.userData.nome} />
                    <div className="text-left ml-3">
                      {/* <p className="profile-name">Richard V.Welsh</p> */}
                      <p className="profile-name">{authData && authData.userData && authData.userData.nome}</p>
                      {/* <small className="designation text-muted text-small">Manager</small>
                      <span className="status-indicator online"></span> */}
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    Manage Accounts
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                  Change Password 
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    Check Inbox
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <button className="btn btn-success btn-block">New Project <i className="mdi mdi-plus"></i></button> */}
            </div>
          </li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Visão Geral</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/class') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicClassMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicClassMenuOpen') } data-toggle="collapse">
              {this.state.loading ?
                <i className="fa fa-spin fa-refresh menu-icon"></i> :
                <i className="fa fa-users menu-icon"></i>
              }
              <span className="menu-title">Minhas Turmas</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.basicClassMenuOpen }>
              <ul className="nav flex-column sub-menu">
                {
                  this.state.listClass.map(
                    cl => <li key={cl} className="nav-item"> <Link className={ this.isPathActive(`/class/${cl}`) ? 'nav-link active' : 'nav-link' } to={{pathname: `/class/${cl}`, state: {dataClass: this.state.dataClass[cl]}}}>{cl}</Link></li>
                  )
                }
                {/* <li className="nav-item"> <Link className={ this.isPathActive('/class/ImaginaKIDS') ? 'nav-link active' : 'nav-link' } to="/class/ImaginaKIDS">ImaginaKIDS</Link></li> */}
              </ul>
            </Collapse>
          </li>

          {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography">Typography</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/form-elements/basic-elements">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">Form Elements</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/tables/basic-table">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title">Tables</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/icons/font-awesome">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title">Icons</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/charts/chart-js">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title">Charts</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title">User Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/blank-page') ? 'nav-link active' : 'nav-link' } to="/user-pages/blank-page">Blank Page</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1">Login</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1">Register</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/user-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/user-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title">Documentation</span>
            </a>
          </li> */}
        </ul>
      </nav>
      // }
      // </AuthUserContext.Consumer>
    );
  }

}

export default withRouter(Sidebar);